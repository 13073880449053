import {isNil, lowerCase} from 'lodash';
import {get, pick, split, take, upperCase} from 'lodash';
import {cleanObjectNilValue} from 'utils/clean';
import {validACN} from 'utils/validator';
import {processAddress} from './common';
import {processCompany} from './company';
import {processEstimate} from './estimate';
import {processPerson} from './person';
import {processSales} from './sales';
import {processSetting} from './settings';
import {processContact} from './contact';
import {processRegulationRegion} from './regulationRegion';
import {processRiskLevel} from './riskLevel';
import {max} from 'date-fns';

export const processUserProfile = (rawData) => {

    try {
        const personDetail = get(rawData, ':person')
            ? processPerson(get(rawData, ':person'))
            : null;
        const companyDetail = get(rawData, ':company')
            ? processCompany(get(rawData, ':company'))
            : null;

        const isCompany = Boolean(lowerCase(get(rawData, 'type')) == 'company');
        const name =
            (isCompany
                ? get(companyDetail, 'name', '')
                : get(personDetail, 'legalName', '')) || get(rawData, 'name','');


        const shortName = upperCase(
            take(split(name, ' '), 2)
                .map((item) => take(item, 1))
                .join(''),
        );
        const roles = get(rawData, ':roles', []).map((item) => item.role);
        return {
            id: get(rawData, 'id'),
            reference: get(rawData, 'ref'),
            referralReference: get(rawData, 'referralCode'),
            referrer: get(rawData, 'referrer'),
            company: get(rawData, 'company'),
            isCompany,
            person: get(rawData, 'person'),
            shortName: shortName||'',
            name: name,
            avatar: get(rawData, ':preference.avatar'),
            email: get(rawData, 'email') || '',
            mobile: get(rawData, 'mobile') || '',
            country: get(rawData, 'region'),
            type: get(rawData, 'type'),
            status: get(rawData, 'accountStatus'),
            kycStatus: get(rawData, 'kycStatus'),
            createdAt: get(rawData, 'createdAt'),
            updatedAt: get(rawData, 'updatedAt'),
            reason: get(rawData, 'reason'),
            personDetail: personDetail,
            person: get(rawData, 'person'),
            companyDetail: companyDetail,
            company: get(rawData, 'company'),
            // estimate: get(rawData, 'estimate'),
            // estimateDetail: processEstimate(get(rawData, ':estimate')),
            // preference: processPreference(get(rawData, ':preference')),
            ...processAddress(get(rawData, ':address'), 'individual'),
            sale: get(rawData, 'sale'),
            saleCode: get(rawData, 'saleCode'),
            saleDetail: get(rawData, ':sale')
                ? processSales(get(rawData, ':sale'))
                : null,
            preference: processSetting(get(rawData, ':preference')),
            contactDetail: processContact(get(rawData, ':contact')),
            termsDetail: {
                accountTerms: Boolean(get(rawData, ':terms.accountTerms')),
                complaintsProcedures: Boolean(
                    get(rawData, ':terms.complaintsProcedures'),
                ),
                privacyPolicy: Boolean(get(rawData, ':terms.privacyPolicy')),
                signature: get(rawData, ':terms.signature'),
            },
            roles: roles,
            kycExpireDate: get(rawData, 'kycExpireDate'),
            riskLevel: get(rawData, ':riskLevel.name'),
            riskLevelDetail: processRiskLevel(get(rawData, ':riskLevel')),
            ibLevel: get(rawData, ':ibLevel.name'),
            isIB: !isNil(get(rawData, ':ibLevel.name')),
            hasIbLevel: !isNil(get(rawData, 'ibLevel')),
            regulation: get(rawData, ':regulation.name'),
            regulationDetail: processRegulationRegion(
                get(rawData, ':regulation'),
            ),
            source: get(rawData, 'source'),
            isImport: get(rawData, 'source') === 'import',
            ibCode: get(rawData, 'ibCode'),
            upLevelIb: get(rawData, ':upLevelIb', {}),
            ref: get(rawData, 'ref'),
            canViewUserPrivacy: Boolean(get(rawData, 'canViewUserPrivacy')),
            amlStatus: get(rawData, 'amlStatus'),
            amlStatusValidTimestamp: get(rawData, 'amlStatusValidTimestamp'),
            amlStatusInvalidTimestamp: get(
                rawData,
                'amlStatusInvalidTimestamp',
            ),
            amlStatusUnverifiedTimestamp: get(
                rawData,
                'amlStatusUnverifiedTimestamp',
            ),
            amlStatusPendingTimestamp: get(
                rawData,
                'amlStatusPendingTimestamp',
            ),
            amlLastUpdatedTimestamp:
                get(rawData, 'amlStatusValidTimestamp') ||
                get(rawData, 'amlStatusInvalidTimestamp') ||
                get(rawData, 'amlStatusUnverifiedTimestamp') ||
                get(rawData, 'amlStatusPendingTimestamp')
                    ? max(
                          [
                              get(rawData, 'amlStatusValidTimestamp'),
                              get(rawData, 'amlStatusInvalidTimestamp'),
                              get(rawData, 'amlStatusUnverifiedTimestamp'),
                              get(rawData, 'amlStatusPendingTimestamp'),
                          ]
                              .filter((d) => d)
                              .map((d) => new Date(d)),
                      ).toISOString()
                    : null,
            rawData: rawData,
            funded: get(rawData, 'funded')?'yes':'no'
        };
    } catch (error) {
        console.log(error);
    }
};
